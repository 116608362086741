<template>

  <span v-for="station in stations" v-bind:key="station">
    <router-link :to="{name: 'Station', params: {station: station}}">{{ station }}</router-link> |
  </span>
  <h1>{{ formatDate(new Date()) }} </h1>
  <table v-if="orders.length">
    <tr>
      <th>
        BstNr 
      </th>
      <th>
        Preis
      </th>
      <th>
        Bestellzeit
        <br>
        Zubereitungszeit
        <br>
        Abholzeit
        abh
      </th>
      <th>
        Bearb.
      </th>
      <th>
        <table class="order-items-header">
          <tr>
            <th>
              Pos.
            </th>
            <th>
              Name
            </th>
            <th>
              Preis
            </th>
            <th>
              Code
            </th>
            <th>
              Station
            </th>
            <th>
              Optionen
            </th>
          </tr>
        </table>
      </th>
    </tr>
    <tr v-for="order in orders" v-bind:key="order.id" :class="{preorder: order.preorder, notified: order.notified}">
      <td class="order-item-tags-parent">
        {{ order.id }}
        <div class="order-item-tags">
        <div v-if="order.percentage != Null" style="padding-left: 10px">
        {{ order.code }}: {{order.percentage}}%
        </div>

          <div v-if="order.preorder && order.notified" class="order-item-tag order-item-preorder-due">
            Vorbestellung
          </div>
          <div v-if="order.preorder && ( ! order.notified) " class="order-item-tag order-item-preorder">
            Vorbestellung
          </div>
          <div v-if="order.cutlery" class="order-item-tag order-item-cutlery">
            Besteck
          </div>
          <div v-if="order.wolt_order_id" class="order-item-tag order-item-wolt">
            Wolt
            {{ order.wolt_order_number }}
          </div>
          <div v-if="order.lieferando_order_key" class="order-item-tag order-item-wolt">
            Lieferando
            {{ order.lieferando_public_reference }}
          </div>
          <div v-if="order.uber_order_id" class="order-item-tag order-item-uber">
            Uber
            {{ order.uber_order_display_id }}
          </div>
          <div v-if="order.lieferando_order_key" class="order-item-tag order-item-lieferando">
            Lieferando
            {{ order.lieferando_public_reference }}
          </div>
          <div v-if="order.location == 'premises'" class="order-item-tag order-item-location">
            Vor Ort
          </div>
          <div v-if="order.location == 'takeaway'" class="order-item-tag order-item-location">
            Takeaway
          </div>
          <div v-if="order.location == 'delivery'" class="order-item-tag order-item-location">
            Lieferung
          </div>
          <div v-if="order.container == 'oneway'" class="order-item-tag order-item-location">
            Einweg
          </div>
          <div v-if="order.container == 'relevo'" class="order-item-tag order-item-location relevo">
            Relevo
            {{ order.relevo_id }}
          </div>
          <div v-if="order.container == 'vytal'" class="order-item-tag order-item-location vytal">
            Vytal
            {{ order.vytal_id }}
          </div>
          <div v-if="order.comment" class="order-item-tag order-item-comment">
            {{ order.comment }}
          </div>
          <div v-if="order.patron" class="order-item-tag order-item-patron">
            {{ order.patron }}
          </div>
          <div v-if="order.cancelled" class="order-item-tag order-item-cancelled">
            Storno
          </div>
        </div>
      </td>
      <td>
        <div v-if="order.percentage == Null">
          <template v-if="order.reported_total && order.reported_total != order.total">
            <strike>{{ as_eur(order.total) }}</strike>
            {{ as_eur(order.reported_total) }}
          </template>
          <template v-else>
            {{ as_eur(order.total) }}
          </template>
        </div>
        <div v-if="order.percentage != Null">
        <strike>{{ as_eur(order.total_pre ) }}</strike><br>
        {{ as_eur(order.total ) }}<br>
        </div>
      </td>
      <td v-if="order.preorder && ( ! order.notify )">
        {{ formatTime(order.received) }}
        <br>
        <br>
        {{ formatTime(order.scheduled) }}
      </td>
      <td v-else-if="order.preorder && ( order.notify )">
        {{ formatTime(order.received) }}
        <br>
        {{ formatTime(order.notify) }}
        <br>
        {{ formatTime(order.scheduled) }}
      </td>
      <td v-else>
        {{ formatTime(order.received) }}
      </td>
      <td class="actions completed" v-if="order.completed">
          {{ formatTime(order.completed) }}
      </td>
      <td class="actions confirmed" v-else-if="order.uber_order_id && ( ! order.scheduled ) ">
        <button v-for="minutes in [5,10,15,20]" v-bind:key="minutes" @click="confirmed(order.id, minutes)">{{minutes}}</button>
      </td>
      <td class="actions confirmed" v-else-if="order.preorder && ( ! order.notify ) ">
        <button v-for="minutes in [5,10,15,20]" v-bind:key="minutes" @click="confirmed(order.id, minutes)">{{minutes}}</button>
      </td>
      <td class="actions confirmed" v-else-if="(order.wolt_order_id) && ( ! order.preorder ) && ( ! order.scheduled )">
        <button v-for="minutes in [5,10,15,20]" v-bind:key="minutes" @click="confirmed(order.id, minutes)">{{minutes}}</button>
      </td>
      <td class="actions done" v-else>
        <button @click="completed(order.id)">Fertig</button>
      </td>
      <td>
        <table class="order-items-body">
          <tr v-for="item in order.items" v-bind:key="item.key">
            <td>
              {{ item.index  }}
              von
              {{ order.items.length }}
            </td>
            <td>
              {{ item.name }}
            </td>
            <td>
              {{ as_eur(item.price) }}
              <small>(inkl. {{ as_percentage(item.tax) }}% MwSt.)</small>
            </td>
            <td>
              {{ item.code }}
            </td>
            <td>
              {{ item.station }}
            </td>
            <td>
              <div v-for="component in item.components" :key="component.id">
                {{ component.name }}
                <small>({{ as_eur(component.price) }} inkl. {{ as_percentage(component.tax) }}% MWSt.)</small>
              </div>
            </td>
          </tr>
        </table>
      </td>
    </tr>
  </table>
  <div v-else>
    Es sind heute noch keine Bestellungen eingegangen
  </div>
</template>

<script>
export default {
  name: 'Orders',
  props: {
    msg: String
  },
  methods: {
    completed(order_id) {
      window.socket.send(JSON.stringify(["completed", order_id]))
    },
    confirmed(order_id, minutes) {
      window.socket.send(JSON.stringify(["confirmed", order_id, minutes]))
    },
    notify(order_id, minutes) {
      window.socket.send(JSON.stringify(["notify", order_id, minutes]))
    }
  },
  computed: {
    stations () {
      let dishes = this.$store.state.dishes
      let stations = []
      for (let i=0; i < dishes[0].stations.length; i++) {
        stations.push(dishes[0].stations[i][1])
      }
      return stations
    },
    orders () {
      // TODO: convert this to an iterator
      var orders = []
      for (var i = this.$store.state.orders.length - 1; i >= 0; i--) {
        //var total = 0
        var raw_order = this.$store.state.orders[i]
        var {items, ...order} = raw_order // shorthand, assign all except 'items' to order
        items = []
        var index = 0
        for (var raw_item of raw_order.items) {
          for (var j = 0; j < raw_item.quantity; j++) {
            index++
            var item = {}
            item.order_id = raw_order.id
            item.key = [raw_item.order_id, raw_item.code, raw_item.index].join("-")
            item.name = raw_item.name
            item.code = raw_item.code
            item.station = raw_item.station
            item.price = raw_item.price
            //total += item.price
            item.tax = raw_item.tax
            item.components = []
            item.index = index
            for (var component of raw_item.components) {
              if (component.index != j) continue
              item.components.push(component)
              // total += component.price
            }
            items.push(item)
          }
        }
        order.items = items
        orders.push(order)
      }
      return orders
    }
  }
}
</script>

<style lang="scss">
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
table {
  th {
    text-align: left;
  }
  td {
    text-align: left;
  }

  button {
    padding: 0 4px;
  }

  .actions.confirmed {
    display: flex;
    flex-direction: row;
    width: 160px;
    flex-wrap: wrap;
    button {
      font-weight: bold;
      font-size: 150%;
      height: 40px;
      width: 40px;
      text-align: center;
    }
  }

  .order-item-tags{
    margin: 1.5em -290px 0 0;
    padding-top: 1em;
    box-sizing: border-box;
  }
  .order-item-tag{
    margin: 2px;
    padding: 3px;
    box-sizing: border-box;
    display:inline-block;
    background: #fff;
    border: 1px #999 solid;
    color: #333;
    &.order-item-comment {
      background: #eef;
      border: 2px #00e solid;
      color: #00f;
    }
    &.order-item-preorder{
      background: #fee;
      border: 2px #f00 solid;
      color: #f00;
    }
    &.order-item-preorder-due{
      background: #efe;
      border: 2px #0f0 solid;
      color: #0f0;
    }
    &.order-item-wolt{
      background: #d3eaf2;
      border: 2px #2596be solid;
      color: #2596be;
    }
    &.order-item-uber{
      background: #e4f2d3;
      border: 2px #00b764 solid;
      color: #00b764;
    }
    &.order-item-lieferando{
      background: #d3eaf2;
      border: 2px #2596be solid;
      color: #2596be;
    }
    &.order-item-location.relevo{
      background: #fdb92e;
      border: 2px #fdb92e solid;
      color: #fff;
      font-weight: bold;
    }
    &.order-item-location.vytal{
      background: #42b983;
      border: 2px #42b983 solid;
      color: #fff;
      font-weight: bold;
    }
    &.order-item-location.cancelled {
      background: #eff;
      border: 2px #fee solid;
      color: #fee;
      font-weight: bold;
    }
  }

  .preorder {
    border-left: 5px red solid;
    &.notified {
      border-left-color: green;
    }
  }

}

</style>
